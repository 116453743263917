import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { keyValDictionary } from '@/app/lib/definition/Dictionarys';

// - Definitation -------------------------------
export const API_URL: keyValDictionary = Object.freeze({
  // User Account Related
  LOGIN: '/identitymanagement/api/identity/tokens/cookies/access',
  LOGIN_NOCOOKIE: '/identitymanagement/api/identity/tokens/access',
  REFRESH_TOKEN: '/identitymanagement/api/identity/tokens/cookies/refresh',
  REFRESH_NOCOOKIE: '/identitymanagement/api/identity/tokens/refresh',
  LOGOUT: '/identitymanagement/api/identity/tokens/cookies/prohibit',
  GET_USER_ACCOUNTS: '/identitymanagement/api/identity/users/accounts/', // + userId e.g. fc2c8f3d-5d31-4bea-837d-1738a8c0d3be
  FORGET_PASSWORD: '/identitymanagement/api/identity/passwords/forgot',
  RESET_PASSWORD: '/identitymanagement/api/identity/passwords/reset',
  CHANGE_PASSWORD: '/identitymanagement/api/identity/passwords/change',
  USER_PROFILE: '/identitymanagement/api/identity/profiles/',

  // Application Services
  GET_APPLICATION: '/applicationmanagement/api/v1/applications/', // + accountId e.g. 13661683-320f-401d-963d-d5606c800427 for majesticks
  GET_EVENT: '/applicationmanagement/api/v1/events/', // + applicationId e.g. Majesticks 9cf617e2-d5b0-4d87-bc72-b6de527e4eda
  GET_FULFILMENT_PREVIEW: '/applicationmanagement/api/v2/fulfilments/previews/events/', // + :eventId/:applicationId
});

export const SITE_MAP = Object.freeze({
  NOT_FOUND: '/not-found',
  LOGOUT: '/logout',
  SESSION_TIMEOUT: '/logout?alt=timeout',
  KICKEDOUT: '/logout?alt=kicked',
});

export const XAPI: keyValDictionary = Object.freeze({
  applicationmanagement: process.env.NEXT_PUBLIC_AM,
  identitymanagement: process.env.NEXT_PUBLIC_IM,
  multimediamanagement: process.env.NEXT_PUBLIC_MM,
  notificationmanagement: process.env.NEXT_PUBLIC_NM,
});

// - Core config --------------------------------
const axiosShared = axios.create({
  baseURL: process.env.NEXT_PUBLIC_GATEWAY_DOMAIN ?? '',
  // timeout: 5000, // Reserve only
  headers: {
    'Content-Type': 'application/json',
    // "Ocp-Apim-Subscription-Key": process.env.NEXT_PUBLIC_SUB_KEY,
  },
  withCredentials: true,
});

axiosShared.interceptors.request.use(
  (config) => {
    // const serviceName = _.head( _.compact( _.split(config.url, "/") ) ) as string
    const serviceName = (config.url?.split('/') ?? []).filter((t) => t !== null && t !== undefined && t !== '')[0];

    if (serviceName in XAPI) {
      config.headers['x-api-key'] = XAPI[serviceName];
      // console.log("AXIOS XAPI", serviceName, config.url, XAPI[serviceName]);
    }

    // if (process.env.NEXT_PUBLIC_J_METHOD_FLAG == "true") {
    //   let bt = getBearToken(true)
    //   if (bt?.length ?? 0 > 0) {
    //     config.headers["Authorization"] = getBearToken(true)
    //   }
    // }

    return config;
  },
  (error) => {
    // (process.env.NODE_ENV === "development")
    //   &&
    console.error('==========\ninstance.interceptors.request.use.err\n==========\n');
    // console.dir(error)

    // TODO: Error reporting
  }
); // End of request interceptors

axiosShared.interceptors.response.use(
  (res) => res,
  (err) => {
    const originalConfig = err.config;

    // (process.env.NODE_ENV === "development")
    //   &&
    console.error(`axios.interceptors.response Error: ${err.response.status}; _retry: ${!originalConfig._retry}`);
    // console.dir(err)

    // console.info("Cookie:", document.cookie)

    return Promise.reject(err);
  }
); // End of response interceptors

const refreshTokenFn = (failRequest: any): Promise<any> => {
  axiosShared.get(API_URL.REFRESH_TOKEN).then(
    () => {
      return Promise.resolve();
    },
    (e) => {
      return Promise.reject(e);
    }
  );

  return Promise.reject('Unknown error');
};

createAuthRefreshInterceptor(axiosShared, refreshTokenFn);

export default axiosShared;

// - SWR ---------
export const getAxiosFetcher = (url: string) => axiosShared.get(url).then((res) => res.data);
// export const getFetcher      = (url: string) => fetch(url, { method: 'GET' }).then(res => res.json());
