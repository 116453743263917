'use client';

import { redirect } from 'next/navigation';
import { SITE_MAP } from './api/api';

export default function GlobalError({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  const logoutBtnTapped = () => {
    redirect(SITE_MAP.SESSION_TIMEOUT);

    // reset && reset()
  };

  return (
    <html>
      <body className='flex items-center justify-start gap-4 bg-blue-50 p-8'>
        <h2>Something went wrong! Please logout and try again</h2>
        {error && (
          <span>
            {error.message} {error.digest}
          </span>
        )}

        <button
          onClick={logoutBtnTapped}
          className='flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
        >
          Logout
        </button>
      </body>
    </html>
  );
}
